import * as actionTypes from "actions";
import { STORE_STATE_IDLE, STORE_STATE_LOADING, STORE_STATE_SUCCEEDED } from "choice";


const ProjectTimeEntriesReducer = (state = actionTypes.projectTimeEntriesInitialState, action) => {
  switch (action.type) {
    case actionTypes.START_FETCH_PROJECT_TIME_ENTRIES:
      return {
        ...state,
        status: STORE_STATE_LOADING
      }
    case actionTypes.FETCH_PROJECT_TIME_ENTRIES:
      return {
        list: action.payload.list,
        page: action.payload.page,
        page_size: action.payload.page_size,
        count: action.payload.count,
        status: STORE_STATE_SUCCEEDED
      };

    case actionTypes.EMPTY_MY_HOURS:
      return {
        list: action.payload.list,
        page: action.payload.page,
        page_size: action.payload.page_size,
        count: action.payload.count,
        status: STORE_STATE_IDLE
      };
    case actionTypes.PROJECT_CHANGED:
      return actionTypes.projectTimeEntriesInitialState

    default:
      return state;
  }
};

export default ProjectTimeEntriesReducer;
