import * as actionTypes from "actions";

const initialState = {
  currentHour: {
    id: 1,
    start_time: null,
    end_tme: null,
    title: "",
    task: {
      id: undefined,
    }
  },
  IsEmpty: true,
};

const HourFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_MY_CURRENT_HOUR:
      return {
        currentHour: action.payload,
        IsEmpty: false,
      };
    case actionTypes.RESET_MY_CURRENT_HOUR:
      return {
        currentHour: initialState.currentHour,
        IsEmpty: false,
      };
    case actionTypes.PROJECT_CHANGED:
      return initialState

    default:
      return state;
  }
};

export default HourFormReducer;
