import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider as StoreProvider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { renderRoutes } from "react-router-config";

import theme from "./theme";
import { configureStore } from "./store";
import routes from "./routes";
import { ScrollReset, GoogleAnalytics } from "./components";
import { RTL } from "./components/hoc";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./assets/scss/index.scss";

const history = createBrowserHistory();
const store = configureStore();

const AppComponent = () => {
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <ScrollReset />
          <ToastContainer
            rtl={theme.direction === "rtl"}
            newestOnTop
          />
          <GoogleAnalytics />
          {renderRoutes(routes)}
        </Router>
      </ThemeProvider>
    </StoreProvider>
  );
};

const App = () => {
  if (theme.direction === "rtl") {
    return (
      <RTL>
        <AppComponent />
      </RTL>
    );
  }
  return <AppComponent />;
};

export default App;
