import { STORE_STATE_IDLE } from "choice";

export const FETCH_PROJECT_TIME_ENTRIES = "FETCH_PROJECT_TIME_ENTRIES"
export const EMPTY_PROJECT_TIME_ENTRIES = "EMPTY_PROJECT_TIME_ENTRIES"
export const START_FETCH_PROJECT_TIME_ENTRIES = "START_FETCH_PROJECT_TIME_ENTRIES"

export const projectTimeEntriesInitialState = {
  page_size: 10,
  page: 1,
  list: [],
  count: 0,
  status: STORE_STATE_IDLE
};

export const fetchProjectTimeEntires = (entries, page, page_size, count) => {
  return {
    type: FETCH_PROJECT_TIME_ENTRIES,
    payload: {
      list: entries,
      page: page,
      page_size: page_size,
      count: count,
    },
  };
};


export const emptyProjectTimeEntries = () => {
  return {
    type: EMPTY_PROJECT_TIME_ENTRIES,
    payload: projectTimeEntriesInitialState
  };
};


export const startFetchProjectTimeEntries = () => {
  return {
    type: START_FETCH_PROJECT_TIME_ENTRIES,
    payload: projectTimeEntriesInitialState
  };
};
