import React, { Fragment, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Drawer, Divider, Paper, Avatar, Typography } from "@material-ui/core";
import { Hidden } from "@material-ui/core";

import useRouter from "utils/useRouter";
import { Navigation } from "components";
import { fetchUserProfile } from "actions";
import { AccountApi } from "api";
import generateNavigationConfig from "./generateNavigationConfig";
// import useLocalStorage from "hooks/useLocalStorage";
import { fetchAccessToken } from "api/tickets";
import useLocalStorage from "hooks/useLocalStorage";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflowY: "auto",
  },
  content: {
    padding: theme.spacing(2),
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
    boxShadow: "rgba(117, 73, 194, 0.25) 1px 2px 20px 5px"
  },
  name: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
  totalDemand: {
    
  }
}));

const NavBar = (props) => {
  const { openMobile, onMobileClose, className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();
  const user = useSelector((state) => state.User);
  const [authToken, setAuthToken] = useLocalStorage('td/token')
 
  
  const dispatch = useDispatch();
  const project = useSelector((state) => state.Project);
  const role = project.default?.member.role || {}

  useEffect(() => {
    if (user.isEmpty) {
      AccountApi.getProfile()
        .then((data) => {
          dispatch(fetchUserProfile(data.result));
        })
        .catch(() => {});
    }
  });

  const navConfig = generateNavigationConfig(role.permissions || [])

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  useEffect(() => {
    role?.permissions?.map((i)=>{
      if (!authToken && i === "true-desk-page") {
        fetchAccessToken().then(resp => {
          setAuthToken(resp.data.result.token)
        })
      }
    })
  },[role.permissions])

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        <Avatar
          alt={`${user.first_name}`}
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          // to="/profile/1/timeline"
        />
        <Typography className={classes.name} variant="h4">
          {user.first_name} {user.last_name}
        </Typography>
        <Typography variant="body2">{role.title}</Typography>
      </div>
        
      {
        user.total_demand > 0 && (
      <div>
      <Typography
            color="primary"
            align="center"
          >
            {`اعتبار کل: ${user.total_demand.toLocaleString()} تومان`}
            </Typography>
      </div>
        )
      }
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {navConfig.map((list) => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
          />
        ))}
      </nav>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor={"left"}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
