import * as actionTypes from "actions";

const initialState = {
  isEmpty: true,
  list: [],
  rowsPerPage: 10,
  page: 0,
  count: 0,
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LIST_INVOICES:
      return {
        ...state,
        isEmpty: false,
        list: action.payload.list,
        page: action.payload.page,
        rowsPerPage: action.payload.rowsPerPage,
        count: action.payload.count,
      };
    case actionTypes.PROJECT_CHANGED:
      return initialState

    default:
      return state;
  }
};

export default invoiceReducer;
