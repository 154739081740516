export const START_FETCH_TIME_ENTRIES_CHART = "START_FETCH_TIME_ENTRIES_CHART"
export const FETCH_TIME_ENTRIES_CHART_THIS_WEEK = "FETCH_TIME_ENTRIES_CHART_THIS_WEEK";
export const FETCH_TIME_ENTRIES_CHART_THIS_MONTH = "FETCH_TIME_ENTRIES_CHART_THIS_MONTH";
export const FETCH_TIME_ENTRIES_CHART_THIS_YEAR = "FETCH_TIME_ENTRIES_CHART_THIS_YEAR";

export const fetchTimeEntriesChartThisWeekData = (payload) => {
  return {
    type: FETCH_TIME_ENTRIES_CHART_THIS_WEEK,
    payload: payload,
  };
};

export const fetchTimeEntriesChartThisMonthData = (payload) => {
  return {
    type: FETCH_TIME_ENTRIES_CHART_THIS_MONTH,
    payload: payload,
  };
};

export const fetchTimeEntriesChartThisYearData = (payload) => {
  return {
    type: FETCH_TIME_ENTRIES_CHART_THIS_YEAR,
    payload: payload,
  };
};


export const startFetchTimeEntriesChartData = () => {
  return {
    type: START_FETCH_TIME_ENTRIES_CHART,
  };
};
