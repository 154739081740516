import {
  AccountCircleOutlined,
  CreditCard,
  DashboardOutlined,
  TimerOutlined,
  HeadsetMic
} from "@material-ui/icons";
import {
  DASHBOARD_TIME_ENTRIES_CHART,
  PROJECT_CREDIT_PAGE,
  PROJECT_INVOICES_PAGE,
  TIME_TRACKER_PAGE,
} from "utils/rbac_permissions";

const perm_nav_map = {};
perm_nav_map[TIME_TRACKER_PAGE] = {
  title: "زمان سنج",
  href: "/time-tracker",
  icon: TimerOutlined,
}

perm_nav_map[DASHBOARD_TIME_ENTRIES_CHART] = {
  title: "ساعات کاری تیم",
  href: "/project-time-entries",
  icon: TimerOutlined,
}

let firstPages = [
  // {
  //   title: "امتیاز دهی",
  //   href: "/feedback",
  //   icon: ThumbsUpDownIcon,
  // },
]

const lastPages = [
  {
    title: "تنظیمات کاربری",
    href: "/developer/profile",
    icon: AccountCircleOutlined,
    children: [
      {
        title: "حساب کاربری",
        href: "/developer/profile/general",
      },
      {
        title: "اعلان ها",
        href: "/developer/profile/notifications",
      },
      {
        title: "امنیت",
        href: "/developer/profile/security",
      },
    ],
  },
];

const generateNavigationConfig = (permissions) => {
  permissions?.map((i)=>{
    if(i === "true-desk-page"){
      firstPages = []
      firstPages.push(
        {
          title: "پیشخوان",
          href: "/dashboard",
          icon: DashboardOutlined,
        },
        {
          title: "پشتیبانی",
          href: "/support",
          icon: HeadsetMic,
        },
      )
    } else {
      firstPages = []
      firstPages.push(
        {
          title: "پیشخوان",
          href: "/dashboard",
          icon: DashboardOutlined,
        },
      )
    }
  })
  const cacheKey = permissions.length.toString();
  if (!generateNavigationConfig.cachedResult) {
    generateNavigationConfig.cachedResult = {};
  }
  if (!generateNavigationConfig.cachedResult[cacheKey]) {
    // make initial
    let pages = firstPages;
    // make single pages
    let key;
    for (key in perm_nav_map) {
      if (permissions.includes(key)) {
        pages.push(perm_nav_map[key]);
      }
    }

    // make sub menus
    let hasFinanceSection =
      permissions.includes(PROJECT_CREDIT_PAGE) ||
      permissions.includes(PROJECT_INVOICES_PAGE);

    if (hasFinanceSection) {
      let item = {
        title: "مدیریت مالی",
        href: "/no",
        icon: CreditCard,
        children: [],
      };
      if (permissions.includes(PROJECT_CREDIT_PAGE)) {
        item.children.push({
          title: "اعتبار",
          href: "/employer/credit",
        });
      }
      if (permissions.includes(PROJECT_INVOICES_PAGE)) {
        item.children.push({
          title: "صورتحساب ها",
          href: "/employer/invoices",
        });
      }
      pages.push(item);
    }

    pages = [...pages, ...lastPages];

    const result = [
      {
        title: "دسترسی",
        pages: pages,
      },
    ];
    generateNavigationConfig.cachedResult[cacheKey] = result;
  }
  return generateNavigationConfig.cachedResult[cacheKey];
};

export default generateNavigationConfig;
