import * as actionTypes from "actions";


const TimeTrackerReducer = (state = actionTypes.timeTrackerInitialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MY_HOURS:
      return {
        list: action.payload.list,
        page: action.payload.page,
        page_size: action.payload.page_size,
        count: action.payload.count,
        isEmpty: false,
      };
    case actionTypes.EMPTY_MY_HOURS:
      return {
        list: action.payload.list,
        page: action.payload.page,
        page_size: action.payload.page_size,
        count: action.payload.count,
        isEmpty: action.payload.isEmpty,
      };
    case actionTypes.ADD_MY_HOURS:
      state = {
        ...state,
        list: [action.payload, ...state.list],
      };
      // console.log(state)
      return state;
    case actionTypes.PROJECT_CHANGED:
      return actionTypes.timeTrackerInitialState

    default:
      return state;
  }
};

export default TimeTrackerReducer;
