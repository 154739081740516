import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const IconTextWrapper = styled("span")(({ theme }) => ({
  "&.icon-text-container": {
    color: "#F59300",
    display: "flex",
    alignItems: "center",
    "& .image": {
      marginInlineEnd: "10px",
    },
  },
}));

export const IconText = ({ icon, text }) => {
  return (
    <IconTextWrapper className="icon-text-container">
      <img src={icon} alt="helper" className="image" />
      <Typography variant="span">{text}</Typography>
    </IconTextWrapper>
  );
};

IconText.propTypes = {};
