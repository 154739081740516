import colors from "./colors";

export default {
  black: colors.absolute.black,
  white: colors.absolute.white,
  primary: {
    contrastText: colors.absolute.white,
    dark: colors.violet[10],
    main: colors.violet[8],
    light: colors.violet[2],
  },
  secondary: {
    contrastText: colors.absolute.white,
    dark: colors.pink[10],
    main: colors.pink[7],
    light: colors.pink[3],
  },
  error: {
    contrastText: colors.absolute.white,
    dark: colors.infrared[10],
    main: colors.infrared[7],
    light: colors.infrared[2],
  },
  success: {
    contrastText: colors.absolute.white,
    dark: colors.twilight[10],
    main: colors.twilight[7],
    light: colors.twilight[2],
  },
  warning: {
    contrastText: colors.absolute.white,
    dark: colors.pink[10],
    main: colors.pink[7],
    light: colors.pink[2],
  },
  text: {
    primary: colors.storm[10],
    secondary: colors.storm[7],
    link: colors.pink[7],
  },
  link: colors.pink[9],
  icon: colors.storm[7],
  background: {
    default: "#ffffff", //"#F4F6F8",
    paper: colors.absolute.white,
  },
  divider: colors.absolute.gray,
  gray: "#1A1A1A99",
};
