import firebase from 'firebase'

const config = {

  apiKey: "AIzaSyDEKwPuaAXRxeCpjOTsmc48uTwDjvPh_0Y",

  authDomain: "bernet-app.firebaseapp.com",  // TODO

  projectId: "bernet-app",

  storageBucket: "bernet-app.appspot.com",

  messagingSenderId: "487921152028",

  appId: "1:487921152028:web:40fc30f1d8bdf31c3defd9",

  measurementId: "G-0MED5T364Y"

};

firebase.initializeApp(config)
export default firebase

export const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null
