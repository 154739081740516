import palette from '../palette';


export default {
  // TODO
  // tooltip: {
  //   color: palette.black,
  //   backgroundColor: palette.white,
  //   fontSize: '14px',
  //   borderTop: '1px',
  //   borderColor: palette.primary.main,
  // },
  // arrow: {
  //   color: palette.white,
  // }
}