import axios from "axios";
import * as Methods from "./Methods";
import { toast } from "react-toastify";


export const BASE_API_DOMAIN = {
  'app.bernetco.ir': 'https://bernetco.ir',
  // 'app.bernetco.ir': 'http://bernetco.ir',
  'b1.bernetco.ir': 'https://staging.bernetco.ir',
  'vahid': "https://44d6-37-255-157-10.ngrok-free.app",
  'localhost:3000': 'http://localhost:8000',
  '127.0.0.1:5500': 'http://localhost:8000',
}

const baseAPI = () => {
  let headers = {};
  const token = window.localStorage.getItem("token");
  if (token) {
    headers.Authorization = token;
  }

  return axios.create({
    baseURL: `${BASE_API_DOMAIN[window.location.host]}/api/v1/`,
    // baseURL: `${BASE_API_DOMAIN["app.bernetco.ir"]}/api/v1/`,
    headers: headers,
  })
};


class BaseApiCallManager {
  constructor(options) {
    if (options && options.baseAPI) {
      this.getBaseAPI = () => {
        return options.baseAPI;
      };
    } else {
      this.baseAPI = baseAPI;
    }
  }

  MakeRequest = (api, method, data) => {
    let resp;
    switch (method) {
      case Methods.GET:
        resp = this.baseAPI().get(api);
        break;
      case Methods.POST:
        resp = this.baseAPI().post(api, data);
        break;
      case Methods.PUT:
        resp = this.baseAPI().put(api, data);
        break;
      case Methods.PATCH:
        resp = this.baseAPI().patch(api, data);
        break;
      case Methods.DELETE:
        resp = this.baseAPI().delete(api, data);
        break;
      default:
        resp = this.baseAPI().get(api);
        break
    }
    return resp
      .then((resp) => {
        return this.handleSuccessResponse(resp);
      })
      .catch((error) => {
        this.handleErrorResponse(error);
      });
  };

  handleSuccessResponse = (resp) => {
    return resp.data;
  };

  handleErrorResponse = (error) => {
    // TODO: toast error here
    if (error.response){
      try {
        toast.error(error.response.data.result.detail);
      } catch {
        toast.error(error.response);
      }
    } else {
      toast.error("خطا در ارتباط با سرور")
    }
  };
}

export default BaseApiCallManager;
