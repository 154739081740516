import BaseApiCallManager from './provider'
import * as methods from './Methods'


class AccountApi extends BaseApiCallManager {
  login = (email, password) => {
    return this.MakeRequest(
      "/account/auth/email/login",
      methods.POST,
      {
        email: email,
        password: password
      }
    )
  }
  getSupportPermission = (id) => {
    return this.MakeRequest(
      `/projects/${id}/user-member/`,
      methods.GET,
    )
  }
  register = (userData) => {
    return this.MakeRequest(
      "/account/register/", 
      methods.POST, 
      userData
    );
  }

  getProfile = () => {
    return this.MakeRequest(
      "/account/profile/",
      methods.GET,
    )
  }
  updateProfile = (data) => {
    return this.MakeRequest(
      "/account/profile/",
      methods.PATCH,
      data
    )
  }

  changePassword = (data) => {
    return this.MakeRequest(
      "/account/profile/change-password/",
      methods.POST,
      data
    )
  }

  updateAvatarProfile = (avatar) => {
    const formData = new FormData();
    formData.append('avatar', avatar)

    return this.MakeRequest(
      "/account/profile/",
      methods.PATCH,
      formData,
    )
  }

  removeAvatarProfile = () => {
    return this.MakeRequest(
      "/account/profile/",
      methods.PATCH,
      {
        avatar: null
      },
    )
  }

  getMembersToScoring = (id, period) => {
    return this.MakeRequest(
      `/score/project/${id}/feedback/?period=${period}`,
      methods.GET,
    )
  }

  getScoringParameters = (id) => {
    return this.MakeRequest(
      '/score/review-points/',
      methods.GET,
    )
  }

  submitScoring = (data) => {
    return this.MakeRequest(
      '/score/feedback/', 
      methods.POST,
      data
    )
  }

  getExamQuestion = () => {
    return this.MakeRequest(
      '/exam/questions/next/',
      methods.GET
    )
  }
  
  ansExamQuestion = (questionId, answerIdsArray) => {
    return this.MakeRequest(
      '/exam/questions/next/answer/',
      methods.POST,
      {
        "question_id": questionId,
        "answer_ids": answerIdsArray
      }
    )
  }

  getScoringAmount = (id) => {
    return this.MakeRequest(
      '/score/',
      methods.GET,
    )
  }

  getPrice = () => {
    return this.MakeRequest(
      '/score/prize',
      methods.GET
    )
  }

  getRols = () => {
    return this.MakeRequest(
      "/account/roles/", 
      methods.GET
    );
  }
  setUserRol = (rolId) => {
    return this.MakeRequest(
      `/account/roles/${rolId}/select/`, 
      methods.POST
    );
  };
  getAllSkills = () => {
    return this.MakeRequest(
      "/account/my-role/skills/",
      methods.GET
    );
  }
  setSkill = (data) => {
    return this.MakeRequest(
      "/account/my-skills/", 
      methods.POST, 
      data
    );
  }
}



export default new AccountApi()