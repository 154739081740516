import BaseApiCallManager from './provider'
import * as methods from './Methods'


class ProjectApi extends BaseApiCallManager {
  getList = () => {
    return this.MakeRequest(
      "/projects/",
      methods.GET,
    )
  }
  getStats = (project_id) => {
    return this.MakeRequest(
      `/projects/${project_id}/stats`,
      methods.GET,
    )
  }

  getDevHistory = (project_id, range) => {
    return this.MakeRequest(
      `/projects/${project_id}/dev-histories/?range=${range}`,
      methods.GET,
    )
  }

  getTimeEntriesChart = (project_id, range) => {
    return this.MakeRequest(
      `/projects/${project_id}/time-entries-chart/?range=${range}`,
      methods.GET,
    )
  }

  getListInvoices = (project_id, page, page_size) => {
    return this.MakeRequest(
      `/projects/${project_id}/invoices/?page=${page}&page_size=${page_size}`,
      methods.GET,
    )
  }

  getInvoiceDetail = (project_id, code) => {
    return this.MakeRequest(
      `/projects/${project_id}/invoices/${code}/`,
      methods.GET,
    )
  }

  chargeBalance = (project_id, amount) => {
    return this.MakeRequest(
      `/projects/${project_id}/charge-balance`,
      methods.POST,
      {
        amount: amount
      }
    )
  }

  offlineChargeBalance = (project_id, form_data) => {
    return this.MakeRequest(
      `/projects/${project_id}/charge-offline-balance`,
      methods.POST,
      form_data,
    )
  }
  getDeveloperStats = (project_id) => {
    return this.MakeRequest(
      `/projects/${project_id}/developer-stats/`,
      methods.GET,
    )
  }
  getTasks = (project_id) => {
    return this.MakeRequest(
      `/projects/${project_id}/tasks/`,
      methods.GET,
    )
  }
  setDoneTask = (project_id, task_id) => {
    return this.MakeRequest(
      `/projects/${project_id}/tasks/${task_id}/setDone`,
      methods.POST,
    )
  }
  createTask = (project_id, title) => {
    return this.MakeRequest(
      `/projects/${project_id}/tasks/`,
      methods.POST,
      {
        title: title
      }
    )
  }

  getTimeEntries = (project_id, page, page_size) => {
    return this.MakeRequest(
      `/projects/${project_id}/time-entries/?page=${page}&page_size=${page_size}`,
      methods.GET,
    )
  }
}



export default new ProjectApi()