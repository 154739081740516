import { createTheme } from "@mui/material/styles";

import palette from "./palette";
import typography from "./typography";
import overrides from "./overrides";
import { faIR } from "@mui/material/locale";

const theme = createTheme(
  {
    palette,
    typography,
    overrides,
    direction: "rtl",
    components: {
      MuiButton: { styleOverrides: { ...overrides.MuiButton } },
      MuiCardActions: { styleOverrides: { ...overrides.MuiCardActions } },
      MuiCardContent: { styleOverrides: { ...overrides.MuiCardContent } },
      MuiCardHeader: { styleOverrides: { ...overrides.MuiCardHeader } },
      MuiChip: { styleOverrides: { ...overrides.MuiChip } },
      MuiIconButton: { styleOverrides: { ...overrides.MuiIconButton } },
      MuiInputBase: { styleOverrides: { ...overrides.MuiInputBase } },
      MuiLinearProgress: { styleOverrides: { ...overrides.MuiLinearProgress } },
      MuiListItemIcon: { styleOverrides: { ...overrides.MuiListItemIcon } },
      MuiOutlinedInput: { styleOverrides: { ...overrides.MuiOutlinedInput } },
      MuiPaper: { styleOverrides: { ...overrides.MuiPaper } },
      MuiTableHead: { styleOverrides: { ...overrides.MuiTableHead } },
      MuiTableCell: { styleOverrides: { ...overrides.MuiTableCell } },
      MuiTableRow: { styleOverrides: { ...overrides.MuiTableRow } },
      MuiToggleButton: { styleOverrides: { ...overrides.MuiToggleButton } },
      MuiTypography: { styleOverrides: { ...overrides.MuiTypography } },
      MuiTooltip: { styleOverrides: { ...overrides.MuiTooltip } },
    },
  },
  faIR
);
// console.log("theme:", theme);

export default theme;
