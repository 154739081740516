export const ADD_MY_CURRENT_HOUR = "ADD_MY_CURRENT_HOUR";
export const RESET_MY_CURRENT_HOUR = "RESET_MY_CURRENT_HOUR";

export const addMyCurrentHours = (payload) => {
  return {
    type: ADD_MY_CURRENT_HOUR,
    payload: payload,
  };
};



export const resetMyCurrentHours = () => {
  return {
    type: RESET_MY_CURRENT_HOUR,
  };
};
