import palette from "./palette";

export default {
  h1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "28px",
    letterSpacing: "-0.24px",
    lineHeight: "32px",
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "23px",
    letterSpacing: "-0.24px",
    lineHeight: "25px",
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "19px",
    letterSpacing: "-0.06px",
    lineHeight: "22px",
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "16px",
    letterSpacing: "-0.06px",
    lineHeight: "19px",
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "14px",
    letterSpacing: "-0.05px",
    lineHeight: "16px",
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "13px",
    letterSpacing: "-0.05px",
    lineHeight: "16px",
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: "16px",
    letterSpacing: "-0.05px",
    lineHeight: "25px",
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: "14px",
    letterSpacing: "-0.05px",
    lineHeight: "21px",
  },
  body1: {
    color: palette.text.primary,
    fontSize: "13px",
    letterSpacing: "-0.05px",
    lineHeight: "21px",
  },
  body2: {
    color: palette.text.secondary,
    fontSize: "12px",
    letterSpacing: "-0.04px",
    lineHeight: "18px",
  },
  button: {
    color: palette.text.primary,
    fontSize: "14px",
  },
  caption: {
    color: palette.text.secondary,
    fontSize: "11px",
    letterSpacing: "0.33px",
    lineHeight: "13px",
  },
  overline: {
    color: palette.text.secondary,
    fontSize: "11px",
    fontWeight: 500,
    letterSpacing: "0.33px",
    lineHeight: "13px",
    textTransform: "uppercase",
  },
  fontFamily: [
    "Shabnam",
    '"Shabnam"',
    "Yekan",
    '"Yekan"',
    "BYekan",
    '"BYekan"',
    "tahoma",
  ].join(","),
  fontWeightRegular: 100,
  fontWeightMedium: 200,
};
