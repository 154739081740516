/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  colors,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/LockOutlined";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import { AccountApi } from "api";
// import axios from 'utils/axios';
import useRouter from "utils/useRouter";
import { NotificationsPopover } from "components";
// import { logout } from 'actions';
import { emptyMyHours, projectChanged } from "actions";
// import useLocalStorage from "hooks/useLocalStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  projectSelect: {
    marginLeft: theme.spacing(1),
    color: theme.palette.white,
  },
  wellComeMsg: {
    backgroundColor: "white",
  },
}));

const TopBar = (props) => {
  const { onOpenNavBarMobile, className, ...rest } = props;

  const classes = useStyles();
  const { history } = useRouter();
  const searchRef = useRef(null);
  const notificationsRef = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);
  const dispatch = useDispatch();
  const project = useSelector((state) => state.Project);

  useEffect(() => {
    let mounted = true;
    const fetchNotifications = () => {
    };
    fetchNotifications();
    return () => {
      mounted = false;
    };
  }, []);
  const handleLogout = () => {
    window.localStorage.clear();
    history.push("/auth/login");
    // dispatch(logout());
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleProjectOnChange = (e) => {
    const value = e.target.value;
    dispatch(projectChanged(value));
    // dispatch(emptyMyHours())
    // persist it to use when page reloads
    localStorage.setItem("project_id", value);
    // redirect to / so root route can redirect user to new dashboard according to the project member role
    // history.push("/");
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <RouterLink to="/">
          <img alt="bernet" src="/images/logos/logo--35-c.png" />
        </RouterLink>
        <div className={classes.flexGrow} />
        <FormControl>
          {/* <InputLabel id="select-project-label">
          پروژه
        </InputLabel> */}
          <Select
            labelId="select-project-label"
            id="select-project-label"
            value={project.default?.id ? project.default?.id.toString() : ""}
            onChange={handleProjectOnChange}
            className={classes.projectSelect}
          >
            {project.listAll.map((item, index) => (
              <MenuItem value={item?.id} key={item?.id}>
                {`پروژه ${item.title}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton
          className={classes.notificationsButton}
          color="inherit"
          onClick={handleNotificationsOpen}
          ref={notificationsRef}
        >
          <Badge
            badgeContent={notifications.length}
            classes={{ badge: classes.notificationsBadge }}
            variant="dot"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Button
          className={classes.logoutButton}
          color="inherit"
          onClick={handleLogout}
        >
          <InputIcon className={classes.logoutIcon} />
          خروج
        </Button>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      {/* <PricingModal
        onClose={handlePricingClose}
        open={pricingModalOpen}
      /> */}
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
