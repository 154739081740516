import { combineReducers } from "redux";

import sessionReducer from "./sessionReducer";
import devChartReducer from "./devChartReducer";
import projectReducer from "./projectReducer";
import userReducer from "./userReducer";
import timeTrackerReducer from "./timeTrackerReducer";
import hourFormReducer from "./hourFormReducer";
import invoiceReducer from "./invoiceReducer";
import invoiceDetailReducer from "./invoiceDetailReducer";
import deviceReducer from "./deviceReducer";
import dashboardStatsReducer from "./dashboardStatsReducer";
import timeEntriesChartReducer from "./timeEntriesChart";
import taskReducer from "./taskReducer";
import ProjectTimeEntriesReducer from './projectTimeEntriesReducer'

const rootReducer = combineReducers({
  session: sessionReducer,
  dashboardDevChart: devChartReducer,
  Project: projectReducer,
  User: userReducer,
  TimeTracker: timeTrackerReducer,
  HourForm: hourFormReducer,
  Invoice: invoiceReducer,
  Device: deviceReducer,
  DashboardStats: dashboardStatsReducer,
  TimeEntriesChart: timeEntriesChartReducer,
  InvoiceDetail: invoiceDetailReducer,
  Task: taskReducer,
  ProjectTimeEntries: ProjectTimeEntriesReducer,
});

export default rootReducer;
