export const FETCH_TASKS = "FETCH_TASKS"
export const START_FETCH_TASKS = "START_FETCH_TASKS"
export const SET_DONE_TASK = "SET_DONE_TASK"
export const CREATE_TASK = "CREATE_TASK"


export const fetchTasks = (payload) => {
  return {
    type: FETCH_TASKS,
    payload: payload,
  };
};


export const startFetchTasks = () => {
  return {
    type: START_FETCH_TASKS,
  };
};



export const setDoneTask = (task_id) => {
  return {
    type: SET_DONE_TASK,
    payload: {
      id: task_id
    }
  };
};



export const createTask = (payload) => {
  return {
    type: CREATE_TASK,
    payload: payload,
  };
};
