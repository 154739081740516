import {
  FormGroup,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import { TextField as MUITextField, } from '@material-ui/core';
import "./TextField.scss";
import { styled } from '@material-ui/core/styles';
import { IconText, Button } from "..";

export function TextField({
  name,
  error,
  label,
  InputProps,
  children,
  ...props
}) {
  return (
    <FormGroup className="textFieldContainer">
      {label ? <FormLabel htmlFor={name}>{label}</FormLabel> : null}
      <MUITextField
        {...props}
        name={name}
        helperText={error?.message}
        error={Boolean(error)}
        InputProps={InputProps}
      >
        {children}
      </MUITextField>
    </FormGroup>
  );
}

export const TextFieldWithoutGroup = ({
  value,
  onChange,
  InputProps,
  children,
  ...props
}) => {
  return (
    <MUITextField
      value={value}
      onChange={onChange}
      {...props}
      InputProps={InputProps}
    >
      {children}
    </MUITextField>
  );
};

const StyledTextField = styled(MUITextField)(({ theme, ltr }) => ({
  "& .MuiFormHelperText-root": {
    margin: "3px 0 !important",
  },
  "& .MuiOutlinedInput-input": {
    direction: ltr ? "rtl" : "ltr",
    padding: "10px",
  },
  "& .MuiOutlinedInput-root": {
    padding: "4px",
    borderRadius: "15px"
  },
  "& .MuiButton-root, & .MuiButtonBase-root": {
    // color: "#fff",
    borderRadius: "6px",
  },
  "& .MuiInput-underline:before": {
    borderBottom: '1px solid #7549C2'
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: '1px solid #7549C2'
  }
}));

export const TextFieldButton = ({
  name,
  error,
  label,
  disabled,
  buttonProps,
  buttonLabel = "",
  loading = false,
  onButtonClick = () => {},
  startIcon = "",
  endIcon = "",
  ltr = false,
  ...props
}) => {
  const endAdornment = buttonLabel ? (
    <InputAdornment position="end">
      <Button
        style={{width: 'fit-content !important'}}
        loading={loading}
        variant="contained"
        // color="primary"
        onClick={onButtonClick}
        disabled={disabled}
        {...buttonProps}
      >
        {buttonLabel}
      </Button>
    </InputAdornment>
  ) : endIcon ? (
    <InputAdornment position="start" onClick={onButtonClick}>
      <img src={endIcon} alt="email" style={{cursor: 'pointer'}}/>
    </InputAdornment>
  ) : null;

  const startAdornment = startIcon ? (
    <InputAdornment position="end">
      <img src={startIcon} alt="email" />
    </InputAdornment>
  ) : null;
  return (
    <StyledTextField
      ltr={ltr}
      name={name}
      label={label}
      helperText={
        error ? (
          <IconText icon="images/components/info.svg" text={error} />
        ) : null
      }
      InputProps={{
        endAdornment,
        startAdornment,
      }}
      {...props}
    />
  );
};
