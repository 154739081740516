import {STORE_STATE_IDLE, STORE_STATE_LOADING, STORE_STATE_SUCCEEDED} from 'choice'
import * as actionTypes from "actions";

const initialState = {
  labels: [],
  data: [],
  status: STORE_STATE_IDLE,
  error: null
};

const data = {
  thisWeek: {
    labels: [
      "شنبه",
      "یکشنبه",
      "دوشنبه",
      "سه‌شنبه",
      "چهارشنبه",
      "پنجشنبه",
      "جمعه",
    ],
  },
  thisMonth: {
    labels: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
    ],
  },
  thisYear: {
    labels: [
      "فروردین",
      "اردیبهشت",
      "خرداد",
      "تیر",
      "مرداد",
      "شهریور",
      "مهر",
      "آبان",
      "آذر",
      "دی",
      "بهمن",
      "اسفند",
    ],
  },
};

const timeEntriesChartReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_FETCH_TIME_ENTRIES_CHART:
      return {
        ...state,
        status: STORE_STATE_LOADING,
      }
    case actionTypes.FETCH_TIME_ENTRIES_CHART_THIS_WEEK:
      return {
        labels: [...data.thisWeek.labels],
        data: action.payload, // get it from api
        status: STORE_STATE_SUCCEEDED
      };
    case actionTypes.FETCH_TIME_ENTRIES_CHART_THIS_MONTH:
      return {
        labels: [...data.thisMonth.labels],
        data: action.payload, // get it from api
        status: STORE_STATE_SUCCEEDED
      };
    case actionTypes.FETCH_TIME_ENTRIES_CHART_THIS_YEAR:
      return {
        labels: [...data.thisYear.labels],
        data: action.payload, // get it from api
        status: STORE_STATE_SUCCEEDED
      };
    case actionTypes.PROJECT_CHANGED:
      return initialState
    default:
      return state;
  }
};

export default timeEntriesChartReducer;
