import * as actionTypes from "actions";

const initialState = {
  isEmpty: true,
  dev_id: '',
  reg_id: '',
}

const deviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DEVICE:
      return {...action.payload, isEmpty: false};
    default:
      return state;
  }
};

export default deviceReducer;
