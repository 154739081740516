import {messaging} from "./firebase";

const requestMessagingPermission = () => {
  if (messaging == null){
    return null
  }
  return messaging
    .requestPermission()
    .then(function() {
      const token = messaging.getToken();
      // console.log("token")
      // console.log(token)
      return token
    })
    .catch(function(err) {
      // console.log("Unable to get permission to notify.", err);
    });
}

export default requestMessagingPermission
