export const PROJECT_CHANGED = 'PROJECT_CHANGED'
export const FETCH_LIST_PROJECTS = 'FETCH_LIST_PROJECTS'


export const projectChanged = (project_id) => {
  return {
    type: PROJECT_CHANGED,
    payload: {
      id: project_id
    }
  };
};


export const fetchListProjects = (payload) => {
  return {
    type: FETCH_LIST_PROJECTS,
    payload: payload,
  };
};
