export const FETCH_DASHBOARD_STATS = "FETCH_DASHBOARD_STATS";
export const START_FETCH_DASHBOARD_STATS = "START_FETCH_DASHBOARD_STATS";

export const fetchDashboardStats = (payload) => {
  return {
    type: FETCH_DASHBOARD_STATS,
    payload: payload
  };
};



export const startFetchDashboardStats = () => {
  return {
    type: START_FETCH_DASHBOARD_STATS,
  };
};
