
export const MEMBER_ROLE_PM = 0
export const MEMBER_ROLE_CTO = 1
export const MEMBER_ROLE_TESTER = 2
export const MEMBER_ROLE_DEVELOPER = 3
export const MEMBER_ROLE_DEVOPS = 4
export const MEMBER_ROLE_REVIEWER = 5
export const MEMBER_ROLE_DESIGNER = 6
export const MEMBER_ROLE_EMPLOYER = 7



export const USER_TYPE_DEVELOPER = 0
export const USER_TYPE_EMPLOYER = 1


export const STORE_STATE_IDLE = "idle"
export const STORE_STATE_LOADING = "loading"
export const STORE_STATE_SUCCEEDED = "succeeded"
export const STORE_STATE_FAILED = "failed"


export const DATE_CHOICE_THIS_WEEK = 0;
export const DATE_CHOICE_THIS_MONTH = 1;
export const DATE_CHOICE_THIS_YEAR = 2;

export const DATE_CHOICES = [
  {
    title: "این هفته",
    value: DATE_CHOICE_THIS_WEEK,
  },
  {
    title: "این ماه",
    value: DATE_CHOICE_THIS_MONTH,
  },
  {
    title: "امسال",
    value: DATE_CHOICE_THIS_YEAR,
  },
];


export const MINIMAL_DATE_CHOICES = [
  {
    title: "این هفته",
    value: DATE_CHOICE_THIS_WEEK,
  },
  {
    title: "این ماه",
    value: DATE_CHOICE_THIS_MONTH,
  },
];


export const CHART_COLORS = [
  '#ffeaa7',
  '#55efc4',
  '#81ecec',
  '#fab1a0',
  '#74b9ff',
  '#a29bfe',
  '#fd79a8',
  '#dfe6e9',
  '#6c5ce7',
  '#ffb8b8',
  '#7efff5',
  '#7d5fff',
  '#4b4b4b',
  '#fff200',
  '#c56cf0',
  '#67e6dc',
  '#17c0eb',
  '#ffaf40',
]
