export default {
  storm: {
    1: "#ECEFF1",
    2: "#CFD8DC",
    3: "#B0BEC5",
    4: "#90A4AE",
    5: "#78909C",
    6: "#607D8B",
    7: "#546E7A",
    8: "#455A64",
    9: "#37474F",
    10: "#263238",
  },
  violet: {
    1: "#673AB733",
    2: "#673AB74C",
    3: "#673AB766",
    4: "#673AB799",
    5: "#673AB7CC",
    6: "#7C50CA",
    7: "#7A4FC7",
    8: "#7549C2",
    9: "#7144C0",
    10: "#673AB7",
  },
  twilight: {
    1: "#DEF6F4",
    2: "#ADE7E2",
    3: "#72D9D0",
    4: "#11C9BC",
    5: "#00BBAB",
    6: "#00AD9A",
    7: "#009F8C",
    8: "#008E7B",
    9: "#007E6B",
    10: "#00614D",
  },
  ultraViolet: {
    1: "#EDE7F6",
    2: "#D1C4E9",
    3: "#B39DDB",
    4: "#9575CD",
    5: "#7E57C2",
    6: "#673AB7",
    7: "#5E35B1",
    8: "#512DA8",
    9: "#4527A0",
    10: "#311B92",
  },
  pink: {
    1: "#FFB6D5",
    2: "#F78FBB",
    3: "#F475AB",
    4: "#FA64A4",
    5: "#F94F97",
    6: "#F53687",
    7: "#F53385",
    8: "#F1277D",
    9: "#E1126B",
    10: "#CA0458",
  },
  infrared: {
    1: "#FDE8E7",
    2: "#FFC8BB",
    3: "#FFA48F",
    4: "#FF7E62",
    5: "#FF5E40",
    6: "#FF4B36",
    7: "#FF311B",
    8: "#F32916",
    9: "#E61F10",
    10: "#CD0004",
  },
  absolute: {
    white: "#FFFFFF",
    gray: "#EEEEEE",
    black: "#000000",
  },
};
