import * as actionTypes from "actions";


const initialState = {
  default: {
    id: -1,
    title: "",
    logo: "",
    balance: 0,
    member: {},
    is_employer: undefined
  },
  listAll: [],
  isEmpty: true,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROJECT_CHANGED: {
      // console.log("PROJECT_CHANGED");
      return {
        default: state.listAll.filter(
          (item) => item.id === action.payload.id
        )[0],
        listAll: state.listAll,
      };
    }
    case actionTypes.FETCH_LIST_PROJECTS:
      // console.log("FETCH_LIST_PROJECTS");
      const project_id = localStorage.getItem("project_id");
      if (project_id) {
        return {
          default: action.payload.filter((item) => item.id == project_id)[0],
          listAll: action.payload,
          isEmpty: false,
        };
      }
      return {
        default: action.payload[0],
        listAll: action.payload,
        isEmpty: false,
      };

    default: {
      const project_id = localStorage.getItem("project_id");
      if (project_id) {
        return {
          default: {
            ...state.listAll.filter((item) => item.id == project_id)[0],
          },
          ...state,
          // isEmpty: true,
        };
      }
      return {
        default: {
          ...state.listAll[0],
        },
        ...state,
        // isEmpty: true,
      };
    }
  }
};

export default projectReducer;
