import axios from 'axios'

const axiosConfig = {
  baseURL: 'https://bernetco.ir/api/v1',
  timeout: 30000

}

const Client = axios.create(axiosConfig)

Client.interceptors.request.use(
  function (request) {
    const token = localStorage.getItem('token')
    // request.headers.setAuthorization(token)
    request.headers.Authorization = token
    return request
  }
)

export default Client
