import * as actionTypes from "actions";
import { STORE_STATE_IDLE, STORE_STATE_LOADING, STORE_STATE_SUCCEEDED } from "choice";


const initialState = {
  code: "",
  tax: 0,
  commission : 0,
  total_amount: 0,
  notes: "",
  items: [],
  status: STORE_STATE_IDLE
};

const invoiceDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DETAIL_INVOICE:
      return {
        ...state,
        ...action.payload,
        status: STORE_STATE_SUCCEEDED,
      };
    case actionTypes.START_FETCH_DETAIL_INVOICE:
      return {
        ...state,
        status: STORE_STATE_LOADING,
      }
    case actionTypes.EMPTY_DETAIL_INVOICE:
      return initialState

    case actionTypes.PROJECT_CHANGED:
      return initialState

    default:
      return state;
  }
};

export default invoiceDetailReducer;
