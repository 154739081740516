import BaseApiCallManager from "./provider";
import * as methods from "./Methods";

class DeviceApi extends BaseApiCallManager {
  create = (reg_id) => {
    return this.MakeRequest("/account/devices/", methods.POST, {
      reg_id: reg_id,
    });
  };

  sync = (dev_id, reg_id) => {
    return this.MakeRequest(`/account/devices/${dev_id}/sync`, methods.POST, {
      dev_id: dev_id,
      reg_id: reg_id,
    });
  };
}

export default new DeviceApi();
