export const FETCH_DETAIL_INVOICE = "FETCH_DETAIL_INVOICE";
export const START_FETCH_DETAIL_INVOICE = "START_FETCH_DETAIL_INVOICE";
export const EMPTY_DETAIL_INVOICE = "EMPTY_DETAIL_INVOICE";

export const fetchDetailInvoice = (payload) => {
  return {
    type: FETCH_DETAIL_INVOICE,
    payload: payload
  };
};


export const startFetchDetailInvoice = (payload) => {
  return {
    type: START_FETCH_DETAIL_INVOICE,
  };
};

export const emptyFetchInvoice = (payload) => {
  return {
    type: EMPTY_DETAIL_INVOICE,
  };
};
