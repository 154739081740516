import React, { Suspense, useEffect, useState } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";

import { NavBar, TopBar } from "./components";
import { WithLoading } from "components/hoc";
import { useDispatch, useSelector } from "react-redux";
import { fetchListProjects, setDevice } from "actions";
import { DeviceApi, ProjectApi } from "api";
import registerMessaging from 'request-messaging-permission';


const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  topBar: {
    zIndex: 2,
    position: "relative",
  },
  container: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: "0 0 auto",
  },
  content: {
    overflowY: "auto",
    overflowX: "hidden",
    flex: "1 1 auto",
  },
}));

const Dashboard = (props) => {
  const { route } = props;
  const dispatch = useDispatch();
  const project = useSelector((state) => state.Project);
  const device = useSelector((state) => state.Device);

  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);


  const SyncDevice = async () => {
    const dev_id = window.localStorage.getItem("dev_id")
    if (dev_id){
      registerMessaging().then(token => {
        DeviceApi.sync(
          dev_id,
          token,
        ).then(data => {
          dispatch(setDevice(data.result))
          props.setLoading(false)
        })
      })
    } else{
      registerMessaging().then(token => {
        DeviceApi.create(
          token,
        ).then(data => {
          window.localStorage.setItem('dev_id', data.result.dev_id)
          dispatch(setDevice(data.result))
          props.setLoading(false)
        })
      })
    }
  }

  useEffect(() => {
    let is_mounted = true
    props.setLoading(true)

    const fetchData = () => {
      if (project.isEmpty) {
        // debugger
        ProjectApi.getList().then((data) => {
          dispatch(fetchListProjects(data.result));
          props.setLoading(false)
        }).catch(error => {
          props.setLoading(false)
        });
      } else {
        props.setLoading(false)
      }
      if (device.isEmpty){
        SyncDevice()
      }
    }
    if (is_mounted){
      fetchData()
    }
    return () => {
      is_mounted = false
    }
  })

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };
  const navBar = (
    <NavBar
      className={classes.navBar}
      onMobileClose={handleNavBarMobileClose}
      openMobile={openNavBarMobile}
    />
  );

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={classes.container}>
        {navBar}
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </main>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default WithLoading(Dashboard);
