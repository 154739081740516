export const FETCH_MY_HOURS = "FETCH_MY_HOURS";
export const ADD_MY_HOURS = "ADD_MY_HOURS";
export const EMPTY_MY_HOURS = "EMPTY_MY_HOURS"

export const timeTrackerInitialState = {
  page_size: 10,
  page: 1,
  list: [],
  count: 0,
  isEmpty: true,
};

export const fetchMyHours = (hours, page, page_size, count) => {
  return {
    type: FETCH_MY_HOURS,
    payload: {
      list: hours,
      page: page,
      page_size: page_size,
      count: count,
    },
  };
};

export const addMyHours = (payload) => {
  return {
    type: ADD_MY_HOURS,
    payload: payload,
  };
};



export const emptyMyHours = () => {
  return {
    type: EMPTY_MY_HOURS,
    payload: timeTrackerInitialState
  };
};