import {STORE_STATE_IDLE, STORE_STATE_LOADING, STORE_STATE_SUCCEEDED} from 'choice'
import * as actionTypes from "actions";

const initialState = {
  list: [],
  status: STORE_STATE_IDLE,
  error: null
};


const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_FETCH_TASKS:
      return {
        ...state,
        status: STORE_STATE_LOADING,
      }
    case actionTypes.FETCH_TASKS:
      return {
        list: action.payload,
        status: STORE_STATE_SUCCEEDED
      };
    case actionTypes.SET_DONE_TASK:
      return {
        ...state,
        list: state.list.filter(item => item.id != action.payload.id)
      }
    case actionTypes.CREATE_TASK:
      return {
        ...state,
        list: [action.payload, ...state.list]
      }
    case actionTypes.PROJECT_CHANGED:
      return initialState
    default:
      return state;
  }
};

export default taskReducer;
