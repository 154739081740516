import {
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { fetchTasks, startFetchTasks } from "actions";
import { ProjectApi } from "api";
import { STORE_STATE_IDLE } from "choice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
  },
}));

const HourFormTask = (props) => {
  const { project_id, value, onChange, error, name, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const Task = useSelector((state) => state.Task);

  useEffect(() => {
    if (Task.status == STORE_STATE_IDLE) {
      dispatch(startFetchTasks());
      ProjectApi.getTasks(project_id).then((data) => {
        dispatch(fetchTasks(data.result));
      });
    }
  });

  return (
    <>
      <FormControl className={classes.select}>
        <InputLabel id="hour-form-task-select-label">وظیفه</InputLabel>
        <Select
          labelId="hour-form-task-select-label"
          id="hour-form-task-select"
          value={value}
          onChange={onChange}
          error={error ? true : false}
          name={name? name: "task"}
        >
          {Task.list.map((task, i) => (
            <MenuItem key={i} value={task.id}>
              {task.title}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default HourFormTask;
