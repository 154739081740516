import { makeStyles, TextField } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  titleInput: {
    width: "100%",
  },
}));

const INPUT_TITLE_LIMIT = 80;

const HourFormTitle = (props) => {
  const classes = useStyles();

  const handleOnChange = (event) => {
    const value = event.target.value;
    if (value.length > INPUT_TITLE_LIMIT || (props.limit && value.length === 0)) {
      return
    }

    props.onChange(event);
  };

  return (
    <>
      <TextField
        className={classes.titleInput}
        id="standard-basic"
        label="شرح"
        name={props.name ? props.name : "title"}
        variant="standard"
        onChange={handleOnChange}
        value={props.value}
        required={props.required}
        onBlur={props.onBlur}
        helperText={`${props.value.length} / ${INPUT_TITLE_LIMIT}`}
      />
    </>
  );
};

export default HourFormTitle;
