import { LinearProgress } from "@material-ui/core";
import React, { useState } from "react";

const WithLoading = (Component) => {
  function HOC(props) {
    const [isLoading, setLoading] = useState(false);

    return (
      <>
        {isLoading
          ? <LinearProgress />
          : <Component {...props} setLoading={setLoading} isLoading={isLoading}/>
        }
      </>
    );
  }
  return HOC;
};

export default WithLoading;
