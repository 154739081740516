export const FETCH_LIST_INVOICES = "FETCH_LIST_INVOICES";


export const fetchListInvoices = (invoices, page, page_size, count) => {
  return {
    type: FETCH_LIST_INVOICES,
    payload: {
      list: invoices,
      page: page,
      rowsPerPage: page_size,
      count: count,
    },
  };
};

