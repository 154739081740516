import * as actionTypes from "actions";

const initialState = {
  labels: [],
  data: [],
  isEmpty: true,
};

const data = {
  thisWeek: {
    data: [12, 22, 9, 12, 14, 8, 3],
    labels: [
      "شنبه",
      "یکشنبه",
      "دوشنبه",
      "سه‌شنبه",
      "چهارشنبه",
      "پنجشنبه",
      "جمعه",
    ],
  },
  thisMonth: {
    data: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
    ],
    labels: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
    ],
  },
  thisYear: {
    data: [10, 5, 11, 20, 13, 28, 18, 4, 13, 12, 13, 5],
    labels: [
      "فروردین",
      "اردیبهشت",
      "خرداد",
      "تیر",
      "مرداد",
      "شهریور",
      "مهر",
      "آبان",
      "آذر",
      "دی",
      "بهمن",
      "اسفند",
    ],
  },
};

const devChartReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DEV_CHART_THIS_WEEK:
      return {
        labels: [...data.thisWeek.labels],
        data: action.payload, // get it from api
        isEmpty: false,
      };
    case actionTypes.FETCH_DEV_CHART_THIS_MONTH:
      return {
        labels: [...data.thisMonth.labels],
        data: action.payload, // get it from api
        isEmpty: false,
      };
    case actionTypes.FETCH_DEV_CHART_THIS_YEAR:
      return {
        labels: [...data.thisYear.labels],
        data: action.payload, // get it from api
        isEmpty: false,
      };
    case actionTypes.PROJECT_CHANGED:
      return initialState
    default:
      return state;
  }
};

export default devChartReducer;
