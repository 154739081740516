import BaseApiCallManager from "./provider";
import * as methods from "./Methods";

class Auth extends BaseApiCallManager {
  register = (userData) => {
    return this.MakeRequest("/account/register/", methods.POST, userData);
  };
  login = (email, password) => {
    return this.MakeRequest("/account/auth/basic/login/", methods.POST, {
      "email": email,
      "password": password,
    });
  };
  loginOtpCodeReq = (phoneNumber) => {
    return this.MakeRequest("/account/auth/opt/request/", methods.POST, {
      "phone_number": phoneNumber,
    });
  };
  loginOtp = (phoneNumber, otpCode) => {
    return this.MakeRequest("/account/auth/opt/login/", methods.POST, {
      "phone_number": phoneNumber,
      "otp": otpCode,
    });
  };
  passResetReq = (email) => {
    return this.MakeRequest(
      "/account/auth/basic/forget-pass/request/",
      methods.POST,
      {
        "email": email,
      }
    );
  };
  changeForgetPass = (queryUidb64, password, queryToken)=> {
    return this.MakeRequest(
      `/account/auth/basic/forget-pass?uidb64=${queryUidb64}&token=${queryToken}`,
      methods.POST,
      {
        "password": password
      }
    )
  }
  
  getExamQuestion = () => {
    return this.MakeRequest(
      '/exam/questions/next/',
      methods.GET
    )
  }
  
  ansExamQuestion = (questionId, answerIdsArray) => {
    return this.MakeRequest(
      '/exam/questions/next/answer/',
      methods.POST,
      {
        "question_id": questionId,
        "answer_ids": answerIdsArray
      }
    )
  }
}
export default new Auth();
