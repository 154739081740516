/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy, useState } from "react";
import { Redirect } from "react-router-dom";
import { DashboardLayout, AuthLayout, ErrorLayout } from "./layouts";
import * as choice from "choice";
import WithLoading from "components/hoc/WithLoading";
import { LinearProgress } from "@material-ui/core";

const ROLE_REDIRECT_MAP = {};
ROLE_REDIRECT_MAP[choice.MEMBER_ROLE_EMPLOYER] = "/employer";
ROLE_REDIRECT_MAP[choice.MEMBER_ROLE_DEVELOPER] = "/developer";
ROLE_REDIRECT_MAP[choice.MEMBER_ROLE_PM] = "/developer";
ROLE_REDIRECT_MAP[choice.MEMBER_ROLE_CTO] = "/developer";
ROLE_REDIRECT_MAP[choice.MEMBER_ROLE_TESTER] = "/developer";
ROLE_REDIRECT_MAP[choice.MEMBER_ROLE_DEVELOPER] = "/developer";
ROLE_REDIRECT_MAP[choice.MEMBER_ROLE_DEVOPS] = "/developer";
ROLE_REDIRECT_MAP[choice.MEMBER_ROLE_REVIEWER] = "/developer";
ROLE_REDIRECT_MAP[choice.MEMBER_ROLE_DESIGNER] = "/developer";

const RoleMiddleware = (props) => {
  const [isLoading, setLoading] = useState(false);
  return (
    <>
      {isLoading ? <LinearProgress /> : <Redirect to={"/dashboard"} />}
      {/* <Redirect to={redirect_url} /> */}
    </>
  );
};

const LoginMiddleware = (Component) => {
  const isLoggedIn = Boolean(window.localStorage.getItem("token"));
  if (!isLoggedIn) {
    return (
      <>
        <Redirect to="/auth/login" />
      </>
    );
  }

  return <Component />;
};

const routes = [
  {
    path: "/",
    exact: true,
    component: () => LoginMiddleware(WithLoading(RoleMiddleware)),
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    routes: [
      {
        path: "/dashboard",
        exact: true,
        component: () => LoginMiddleware(lazy(() => import("views/Dashboard"))),
      },
    ],
  },
  {
    path: "/feedback",
    component: AuthLayout,
    routes: [
      {
        path: "/feedback",
        exact: true,
        component: () => LoginMiddleware(lazy(() => import("views/Scoring"))),
      },
    ],
  },
  {
    path: "/time-tracker",
    component: DashboardLayout,
    routes: [
      {
        path: "/time-tracker",
        exact: true,
        component: () =>
          LoginMiddleware(lazy(() => import("views/TimeTracker"))),
      },
    ],
  },
  {
    path: "/project-time-entries",
    component: DashboardLayout,
    routes: [
      {
        path: "/project-time-entries",
        exact: true,
        component: () =>
          LoginMiddleware(lazy(() => import("views/TeamTimeEntries"))),
      },
    ],
  },
  {
    path: "/employer",
    component: DashboardLayout,
    routes: [
      {
        path: "/employer/profile",
        exact: true,
        component: () =>
          LoginMiddleware(lazy(() => import("views/Employer/Profile"))),
      },
      {
        path: "/employer/profile/:tab",
        exact: true,
        component: () =>
          LoginMiddleware(lazy(() => import("views/Employer/Profile"))),
      },
      {
        path: "/employer/development/pending-tasks",
        exact: true,
        component: () =>
          LoginMiddleware(lazy(() => import("views/Employer/PendingTasks"))),
      },
      {
        path: "/employer/invoices",
        exact: true,
        component: () =>
          LoginMiddleware(lazy(() => import("views/Employer/InvoiceList"))),
      },
      {
        path: "/employer/invoices/:code",
        exact: true,
        component: () =>
          LoginMiddleware(lazy(() => import("views/Employer/InvoiceDetail"))),
      },
      {
        path: "/employer/transactions",
        exact: true,
        component: () =>
          LoginMiddleware(lazy(() => import("views/Employer/TransactionList"))),
      },
      {
        path: "/employer/credit",
        exact: true,
        component: () =>
          LoginMiddleware(lazy(() => import("views/Employer/Credit"))),
      },
    ],
  },
  {
    path: "/developer",
    component: DashboardLayout,
    routes: [
      {
        path: "/developer/profile",
        exact: true,
        component: () =>
          LoginMiddleware(lazy(() => import("views/Employer/Profile"))), // TODO: change it
      },
      {
        path: "/developer/profile/:tab",
        exact: true,
        component: () =>
          LoginMiddleware(lazy(() => import("views/Employer/Profile"))), // TODO: change it
      },
    ],
  },
  {
    path: "/support",
    component: DashboardLayout,
    routes: [
      {
        path: "/support",
        exact: true,
        component: lazy(() => import("./views/support")),
      },
      {
        path: "/support/tickets/:id",
        exact: true,
        component: lazy(() => import("./views/ticket")),
      },
      {
        path: "/support/create-ticket",
        exact: true,
        component: lazy(() => import("./views/createTicket")),
      },
    ],
  },
  {
    path: "/auth",
    component: AuthLayout,
    routes: [
      {
        path: "/auth/login",
        exact: true,
        component: lazy(() => import("views/Login")),
      },
      {
        path: "/auth/sign-up",
        exact: true,
        component: lazy(() => import("views/SignUp")),
      },
      {
        path: "/auth/roles",
        exact: true,
        component: lazy(() => import("./views/Auth/Roles")),
      },
      {
        path: "/auth/speciality",
        exact: true,
        component: lazy(() => import("./views/Auth/Speciality")),
      },
    ],
  },
  {
    path: "/questionnaire",
    component: AuthLayout,
    routes: [
      {
        path: "/questionnaire",
        exact: true,
        component: lazy(() => import("./views/Auth/Questionnaire")),
      },
    ],
  },

  {
    path: "/errors",
    component: ErrorLayout,
    routes: [
      {
        path: "/errors/error-401",
        exact: true,
        component: lazy(() => import("views/Error401")),
      },
      {
        path: "/errors/error-404",
        exact: true,
        component: lazy(() => import("views/Error404")),
      },
      {
        path: "/errors/error-500",
        exact: true,
        component: lazy(() => import("views/Error500")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    component: () => <Redirect to="/errors/error-404" />,
  },
];

export default routes;
