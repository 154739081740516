// dashboard permissions
export const DASHBOARD_MY_TEAM_CARD = "dashboard-my-team"
export const DASHBOARD_MY_SCORE_CARD = "dashboard-my-score"
export const DASHBOARD_MY_BALANCE = "dashboard-my-balance"
export const DASHBOARD_TIME_TRACKER = "dashboard-time-tracker"
export const DASHBOARD_SPRINT_PERCENT = "dashboard-sprint-percent"
export const DASHBOARD_PROJECT_BALANCE = "dashboard-project-balance"
export const DASHBOARD_REAL_TIME_ACTIVITIES = "dashboard-real-time-activities"
export const DASHBOARD_DEV_HISTORY = "dashboard-dev-history"
export const PROJECT_CREDIT_PAGE = 'project-credit-page'
export const PROJECT_INVOICES_PAGE = 'project-invoices-page'
export const TIME_TRACKER_PAGE = 'time-tracker-page'
export const DASHBOARD_TIME_ENTRIES_CHART = 'dashboard-time-entries-chart'
export const DASHBOARD_TASK_MANAGER = 'dashboard-task-manager'
