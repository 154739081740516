import BaseApiCallManager from "./provider";
import * as methods from "./Methods";

class TimeTrackerApi extends BaseApiCallManager {
  getList = (page, page_size, project_id) => {
    return this.MakeRequest(
      `/time-tracker/entries/?project_id=${project_id}&page=${page}&page_size=${page_size}`,
      methods.GET
    );
  };
  start = (title, task_id, project_id) => {
    return this.MakeRequest("/time-tracker/start/", methods.POST, {
      title: title,
      task_id: task_id,
      project_id: project_id,
    });
  };
  stop = (title, task_id) => {
    return this.MakeRequest("/time-tracker/stop/", methods.POST, {
      title: title,
      task_id: task_id,
    });
  };

  getCurrent = () => {
    return this.MakeRequest("/time-tracker/current/", methods.GET);
  };

  setCurrentTitle = (title, task_id) => {
    return this.MakeRequest(
      "/time-tracker/current/setTitle",
      methods.POST,
      {
        title: title,
        task_id: task_id,
      }
    );
  };

  delete = (id) => {
    return this.MakeRequest(`/time-tracker/entries/${id}/`, methods.DELETE);
  };

  get = (id) => {
    return this.MakeRequest(`/time-tracker/entries/${id}/`, methods.GET);
  };

  update = (id, data) => {
    return this.MakeRequest(`/time-tracker/entries/${id}/`, methods.PUT, data);
  };

  create = (data) => {
    return this.MakeRequest(`/time-tracker/entries/`, methods.POST, data);
  };

  getListChangeLogs = (id) => {
    return this.MakeRequest(
      `/time-tracker/entries/${id}/change-logs/`,
      methods.GET
    );
  };
}

export default new TimeTrackerApi();
