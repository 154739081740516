import {STORE_STATE_IDLE, STORE_STATE_LOADING, STORE_STATE_SUCCEEDED} from 'choice'
import * as actionTypes from "actions";


const initialState = {
  score: 0,
  members_count: 0,
  sprint_progress_percentage: 0,
  balance: 0,
  project_balance: 0,
  current_activities: {
    doing_tasks: 0,
    busy_members: 0,
    activities: []
  },
  status: STORE_STATE_IDLE,
  error: null
}


const dashboardStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_FETCH_DASHBOARD_STATS:
      return {
        ...state,
        status: STORE_STATE_LOADING,
      }
    case actionTypes.FETCH_DASHBOARD_STATS:
      return {
        ...action.payload,
        status: STORE_STATE_SUCCEEDED,
        error: null,
      }
    case actionTypes.PROJECT_CHANGED:
      return initialState
  
    default:
      return state
  }
}


export default dashboardStatsReducer
