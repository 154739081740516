import Client from './client'
import axios from 'axios'

let headers = {};
  const td_token = localStorage.getItem('td/token')
  if(td_token && td_token !== 'undefined'){
    headers.accesstoken = JSON.parse(localStorage.getItem('td/token'))
  }
  const axiosConfig = {
    baseURL: 'https://trudesk.bernetco.ir/api/v1',
    timeout: 30000,
    headers: headers,
  }
const TDClient = axios.create(axiosConfig)
// TDClient.interceptors.request.use(
//   function (request) {
//     const token = JSON.parse(localStorage.getItem('td/token'))
//     request.headers.set('accesstoken', token)
//     return request
//   }
// )

export const fetchAccessToken = () => {
  return Client.get('/truedesk/access-token/')
}

export const fetchTicketTypes = () => {
  return TDClient.get('/tickets/types/')
}

export const fetchGroups = () => {
  return TDClient.get('/groups/')
}

export const fetchProfile = () => {
  return TDClient.get('/login/')
}

export const createTicket = (data) => {
  return TDClient.post('/tickets/create/', data)
}

export const fetchTickets = () => {
  return TDClient.get('/tickets/')
}

export const fetchTicket = (id) => {
  return TDClient.get(`/tickets/${id}/`)
}

export const addCommentToTicket = (id, comment) => {
  const data = {
    _id: id,
    comment
  }
  return TDClient.post('/tickets/addcomment/', data)
}

export const TICKET_STATUS_MAP = {
  0: 'جدید',
  1: 'باز',
  2: 'در حال بررسی',
  3: 'بسته شده'
}
