import React, { useEffect } from "react";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function RTL(props) {
  useEffect(() => {
    document.body.setAttribute("dir", "rtl");
  });
  return (
    // <div dir="rtl">
      <StylesProvider jss={jss}>{props.children}</StylesProvider>
    // </div>
  );
}

export default RTL;
