import { Button as MUIButton, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

export const Button = ({
  href,
  loading,
  onClick,
  children,
  endIcon: EndIcon,
  startIcon: StartIcon,
  ...props
}) => {
  const history = useHistory();

  const onClickHandler = (e) => {
    e.preventDefault();
    if (href) {
      history.push(href);
    }
  };

  const endIcon = EndIcon
    ? {
        endIcon: <EndIcon />,
      }
    : undefined;
 const startIcon = StartIcon
    ? {
      startIcon: <StartIcon />,
      }
    : undefined;

  return (
    <MUIButton onClick={href ? onClickHandler : onClick} {...props} {...endIcon} {...startIcon}>
      {loading ? (
        <CircularProgress sx={{ color: "white" }} size="24px" />
      ) : (
        children
      )}
    </MUIButton>
  );
};

Button.propTypes = {
  href: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  icon: PropTypes.object,
};
