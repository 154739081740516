import * as actionTypes from "actions";

const initialState = {
  isEmpty: true,
  avatar: null,
  phone_number: '',
  total_demand: -1
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_PROFILE:
      return {...action.payload, isEmpty: false};
    default:
      return state;
  }
};

export default userReducer;
